@use '~@angular/material' as mat;
@import '@angular/material/theming';

@include mat.core();

/* テーマカラーのカスタマイズ */
// 色定義： オプションは hue カラーパレットで色コード指定, 参照: https://material.io/design/color/ and https://material.io/tools/color/
$my-app-primary: mat.define-palette(mat.$brown-palette, 500);
$my-app-accent:  mat.define-palette(mat.$pink-palette, A200);
$my-app-warn:    mat.define-palette(mat.$red-palette, 500);

// テーマとして色定義をまとめる
$my-app-theme: mat.define-light-theme($my-app-primary, $my-app-accent, $my-app-warn);

// カスタムテーマを適用
@include mat.all-component-themes($my-app-theme);
