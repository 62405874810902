/* You can add global styles to this file, and also import other style files */

html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  font-family: Roboto, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ' ,Meiryo, 'ＭＳ Ｐゴシック', 'Noto Sans JP', sans-serif;
}
.container {
  width: 100%;
  height: calc(100% - 60px);
}

/* ボタン共通 */
.button-container {
  padding: 8px;
  text-align: center;
}
.button-container > button {
  margin: 8px;
  padding: 2px 16px;
}
span.btn-label {
  margin-left: 0.35rem;
  font-weight: bold;
}

/* Text */
.text-bolder {
  font-weight: bolder;
}
.text-danger {
  color: #F44336;  /* Red 500 */
}
.text-warning {
  color: #FFFF00;  /* Yellow A200 */
}

/* Table */
.td-center {
  text-align: center;
}
.td-right {
  text-align: right;
}
/* thタグ */
th.mat-table-sticky {
  z-index: 2 !important;
}
/* thタグの横幅の設定 */
th.column-width-20 {
  width: 20px;
}
th.column-width-40 {
  width: 40px;
}
th.column-width-110 {
  width: 110px;
}
th.column-width-200 {
  width: 200px;
}

/* Hint */
.mat-hint {
  font-size: 13px;
}

/* Datepicker */
input.select-date-label {
  color: rgba(0, 0, 0, 0.87) !important;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.87) !important;
  opacity: 1;
  line-height: 19px;
}

/* iOSなどのプラットフォーム独自の装飾仕様をクリアする */
* {
  -webkit-appearance: none;
}
